frappe.provide("frappe.views");

frappe.listview_settings['Expiration Reminder'] = {
	show_views: ['Calendar']
}

frappe.views.ExpirationReminderView = class ExpirationReminderView extends frappe.views.ListView {

	setup_view() {
		this.render_header();
		this.setup_events();
		this.add_file_action_buttons();
		this.get_expiring_docs();
		this.$filter_section.hide()
	}

	get_expiring_docs() {
        let me = this;
		return frappe
			.call("finse.api.expiration_reminder")
			.then((doc) => {
                me.render_cards(doc)
            });
	}

    render_html(obj) {
        return `
        <div class="ce-block col-xs-6 col-sm-4 col-md-2 col-lg-2 expiry-card" style="margin-top:10px" data-filter="${obj.filter}">
            <div class="ce-block__content">
                <div class="widget shortcut-widget-box"  style="background: whitesmoke; padding-left: 10px">
                    <div class="widget-head">
                        <div class="widget-label">
                            <div class="widget-title">
                                <span class="ellipsis" title="${obj.label}">${obj.label}</span>
                            </div>
                        </div>
                        <div class="widget-control"><div class="indicator-pill ${obj.color}">${obj.value}</div></div>
                    </div>
                </div>
            </div>
        </div>
        `
    }

    render_cards(data) {
        let html = '<div class="row" style="margin-bottom: 20px;">'
        for (let obj of data.message) {
            obj.color = obj.filter == "Expired" ? "red" : "orange"
            html += this.render_html(obj);
        }
        html += '</div>';
        this.$page.find(".page-toolbar").html(html).removeClass('hide');
        this.setup_card_events()
    }

	setup_card_events() {
        $(".expiry-card").click(async (e) => {
            e.stopPropagation();
            let filter = $(e.currentTarget).data("filter");

            if (filter == "Expired") {
				this.filters = [["Expiration Reminder", "status", "=", 'Expired', true]];

            } else {
				this.filters = [["Expiration Reminder", "expiry_date", "Between", filter.split(","), true]];
            }
            if (this.filters.length > 0) {
				await this.filter_area
					.clear(false)
					.then(() => this.filter_area.set(this.filters));
			} else {
				await this.filter_area.clear(false);
			}

            this.refresh();
        })
	}

	setup_no_result_area() {
		this.$no_result = $(`<div class="no-result">
			<div class="breadcrumbs">${this.get_breadcrumbs_html()}</div>
			<div class="text-muted flex justify-center align-center">
				${this.get_no_result_message()}
			</div>
		</div>`).hide();

		this.$frappe_list.append(this.$no_result);
	}

	before_refresh() {

	}

	setup_defaults() {
		return super.setup_defaults().then(() => {
			const route = frappe.get_route();
			this.current_folder = route.slice(3).join("/");
			if (this.current_folder) {
				this.filters = [["Expiration Reminder", "folder", "=", this.current_folder, true]];
			} else {
				this.filters = [["Expiration Reminder", "folder", "is", "not set", true]];
			}

			//this.filters = this.filters.concat(this.new_filters || [])
			this.order_by = this.view_user_settings.order_by || "file_name asc";

			this.menu_items = this.menu_items.concat(this.file_menu_items());
		});
	}

	file_menu_items() {
		const items = [
			{
				label: __("New Folder"),
				action: () => {
					frappe.prompt(
						__("Name"),
						(values) => {
							if (values.value.indexOf("/") > -1) {
								frappe.throw(__("Folder name should not include '/' (slash)"));
							}
							const data = {
								file_name: values.value,
								folder: this.current_folder,
							};
							frappe.call({
								method: "frappe.core.api.file.create_new_folder",
								args: data,
							});
						},
						__("Enter folder name"),
						__("Create")
					);
				},
			},
			{
				label: __("Import Zip"),
				action: () => {
					new frappe.ui.FileUploader({
						folder: this.current_folder,
						restrictions: {
							allowed_file_types: [".zip"],
						},
						on_success: (file) => {
							frappe.show_alert(__("Unzipping files..."));
							frappe
								.call("frappe.core.api.file.unzip_file", {
									name: file.name,
								})
								.then((r) => {
									if (r.message) {
										frappe.show_alert(__("Unzipped {0} files", [r.message]));
									}
								});
						},
					});
				},
			},
		];

		return items;
	}

	add_file_action_buttons() {
		this.$cut_button = this.page
			.add_button(__("Cut"), () => {
				frappe.file_manager.cut(this.get_checked_items(), this.current_folder);
				this.$checks.parents(".file-wrapper").addClass("cut");
			})
			.hide();

		this.$paste_btn = this.page
			.add_button(__("Paste"), () => frappe.file_manager.paste(this.current_folder))
			.hide();

		this.page.add_actions_menu_item(__("Export as zip"), () => {
			let docnames = this.get_checked_items(true);
			if (docnames.length) {
				open_url_post("/api/method/frappe.core.api.file.zip_files", {
					files: JSON.stringify(docnames),
				});
			}
		});
	}

	prepare_data(data) {
		super.prepare_data(data);

		this.data = this.data.map((d) => this.prepare_datum(d));

		// Bring folders to the top
		const { sort_by } = this.sort_selector;
		if (sort_by === "title") {
			this.data.sort((a, b) => {
				if (a.is_folder && !b.is_folder) {
					return -1;
				}
				if (!a.is_folder && b.is_folder) {
					return 1;
				}
				return 0;
			});
		}
	}

	prepare_datum(d) {


		let icon_class = "";
		let type = "";
		if (d.is_folder) {
			icon_class = "folder-normal";
			type = "folder";
		} else {
			icon_class = "file";
			type = "file";
		}

		let title = d.title;
		d._title = title;
		d.icon_class = icon_class;
		d._type = type;

		d.subject_html = `
			${frappe.utils.icon(icon_class)}
			<span>${title}</span>
			${d.is_private ? '<i class="fa fa-lock fa-fw text-warning"></i>' : ""}
		`;
		return d;
	}

	get_args() {
		const args = super.get_args();
		args.fields.push("`tabExpiration Reminder`.`is_folder`")
		return args;
	}

	get_breadcrumbs_html() {
		const route = frappe.router.parse();
		const folders = route.slice(3);

		if (!folders.length) return '';

		return `<a href="/app/expiration-reminder">Home</a>&nbsp;/&nbsp;` + folders
			.map((folder, i) => {
				if (i === folders.length - 1) {
					return `<a href="/app/expiration-reminder/${folder}">${folder}</a>`;
				}
				const route = folders.reduce((acc, curr, j) => {
					if (j <= i) {
						acc += "/" + curr;
					}
					return acc;
				}, "/app/expiration-reminder/view/list");

				return `<a href="${route}">${folder}</a>`;
			})
			.join("&nbsp;/&nbsp;");
	}

	get_header_html() {
		const breadcrumbs_html = this.get_breadcrumbs_html();

		let header_selector_html = !frappe.views.FileView.grid_view
			? `<input class="level-item list-check-all hidden-xs" type="checkbox" title="${__(
					"Select All"
			  )}">`
			: "";

		let header_columns_html = !frappe.views.FileView.grid_view
			? `<div class="list-row-col ellipsis hidden-xs">
					<span>${__("Status")}</span>
				</div>
				<div class="list-row-col ellipsis hidden-xs">
					<span>${__("Expiry Date")}</span>
				</div>`
			: "";

		let subject_html = `
			<div class="list-row-col list-subject level" style="font-size: 16px">
				${header_selector_html}
				<span class="level-item">${breadcrumbs_html}</span>
			</div>
			${header_columns_html}
		`;

		return this.get_header_html_skeleton(subject_html, '<span class="list-count"></span>');
	}

	get_route_url(file) {
		return file.is_folder ? "/app/expiration-reminder/view/list/" + file.name : this.get_form_link(file);
	}

	get_left_html(file) {
		file = this.prepare_datum(file);
		const route_url = this.get_route_url(file);
		const get_df = frappe.meta.get_docfield.bind(null, this.doctype);

		const df = get_df(this.meta.expiry_date)

		return `
			<div class="list-row-col ellipsis list-subject level">
				<span class="level-item file-select">
					<input class="list-row-checkbox"
						type="checkbox" data-name="${file.name}">
				</span>
				<span class="level-item  ellipsis" title="${file.title}">
					<a class="ellipsis" href="${route_url}" title="${file.title}">
						${file.subject_html}
					</a>
				</span>
			</div>
			<div class="list-row-col ellipsis hidden-xs text-muted">
				${this.get_indicator_html(file)}
			</div>
			<div class="list-row-col ellipsis hidden-xs text-muted">
				<span>${frappe.format(file.expiry_date, df, null, file)}</span>
			</div>
		`;
	}

	toggle_result_area() {
		super.toggle_result_area();
		this.toggle_cut_paste_buttons();
	}

	on_row_checked() {
		super.on_row_checked();
		this.toggle_cut_paste_buttons();
	}

	toggle_cut_paste_buttons() {
		const hide_paste_btn =
			!frappe.file_manager.can_paste ||
			frappe.file_manager.old_folder === this.current_folder;
		const hide_cut_btn = !(this.$checks && this.$checks.length > 0);

		this.$paste_btn.toggle(!hide_paste_btn);
		this.$cut_button.toggle(!hide_cut_btn);
	}
};