export default class Column {
	constructor(section, df, column_count, row_count) {
		if (!df) df = {};

		this.df = df;
		this.section = section;
		this.make(row_count);
		this.resize_all_columns(column_count, row_count);
	}

	make(row_count) {
		this.wrapper = $(`
			<div class="form-column row-${row_count}">
				<form>
				</form>
			</div>
		`)
			.appendTo(this.section.body)
			.find("form")
			.on("submit", function () {
				return false;
			});

		if (this.df.label) {
			$(`
				<label class="control-label">
					${__(this.df.label)}
				</label>
			`)
				.appendTo(this.wrapper);
		}
	}

	resize_all_columns(column_count, row_count) {
		// distribute all columns equally
		let colspan = cint(12 / column_count);

		this.section.wrapper
			.find(`.row-${row_count}`)
			.removeClass()
			.addClass("form-column")
			.addClass(`row-${row_count}`)
			.addClass("col-sm-" + colspan);

	}

	refresh() {
		this.section.refresh();
	}
}