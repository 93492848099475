import "./js/navbar.html";
//import "./js/form_sidebar.html";
//import "./js/form_sidebar.js";
import "./js/page.html";
import "./js/workflow";
import "./js/expiration_reminder";

import Column from "./js/column.js";

frappe.ui.form.Layout = class Layout extends frappe.ui.form.Layout {
	constructor (opts) {
		super(opts)
		this.column_count = 1;
		this.row_count = 1;
	}

	render(new_fields) {
		let fields = new_fields || this.fields;

		this.section = null;
		this.column = null;

		if (this.no_opening_section() && !this.is_tabbed_layout()) {
			this.fields.unshift({fieldtype: 'Section Break'});
		}

		if (this.is_tabbed_layout()) {
			let default_tab = {label: __('Details'), fieldname: 'details', fieldtype: "Tab Break"};
			let first_tab = this.fields[1].fieldtype === "Tab Break" ? this.fields[1] : null;
			if (!first_tab) {
				this.fields.splice(1, 0, default_tab);
			}
		}

		fields.forEach(df => {
			switch (df.fieldtype) {
				case "Fold":
					this.make_page(df);
					break;
				case "Section Break":
					this.column_count = 1;
					this.row_count = 1;
					this.make_section(df);
					break;
				case "Column Break":
					if (!df.hidden)  {
						if (df.options?.toLowerCase() == 'full') {
							this.column_count = 1;
							this.row_count++;
						} else {
							this.column_count++;
						}

						this.make_column(df);
					}
					break;
				case "Tab Break":
					this.column_count = 1;
					this.row_count = 1;
					this.make_tab(df);
					break;
				default:
					this.make_field(df);
			}
		});
	}

	make_column(df) {
		this.column = new Column(this.section, df, this.column_count, this.row_count);
		if (df && df.fieldname) {
			this.fields_list.push(this.column);
		}
	}
};


frappe.ui.form.ControlLink = class ControlLink extends frappe.ui.form.ControlLink {
	make_input() {
		super.make_input();
		let me = this;
		this.$input.on("focus", function() {
			me.previous_value = me.$input.val()
			document?.activeElement?.select();
		})
		this.$input.keydown((e) => {
			if (e.which == 27 || e.keyCode == 27) this.$input.val(me.previous_value);
		})
	}
	set_mandatory(value) {
		this.$wrapper.toggleClass("has-error", (this.df.reqd && is_null(value)));
	}

}

// frappe.ui.form.ControlData = class ControlData extends frappe.ui.form.ControlData {
// 	make_input() {
// 		super.make_input();
// 		let me = this;
// 		this.$input.on("focus", function() {
// 			me.previous_value = me.$input.val()
// 			document.activeElement && document.activeElement.select();
// 		})
// 		this.$input.keydown((e) => {
// 			if (e.which == 27 || e.keyCode == 27) this.$input.val(me.previous_value);
// 		})
// 	}
// }

frappe.views.Workspace = class Workspace extends frappe.views.Workspace {
	get_page_to_show() {
		let default_page = { name: "Accounting", public: true };

		let page =
			(frappe.get_route()[1] == "private" ? frappe.get_route()[2] : frappe.get_route()[1]) ||
			default_page.name;
		let is_public = frappe.get_route()[1]
			? frappe.get_route()[1] != "private"
			: default_page.public;
		return { name: page, public: is_public };
	}
	setup_actions(page) {
		if (frappe.session.user == 'Administrator') {
			super.setup_actions(page);
			$("#page-Workspaces .page-head").toggle(true);
		}
	}
}

// frappe.standard_pages['Workspaces'] = function() {
// 	var wrapper = frappe.container.add_page('Workspaces');

// 	frappe.ui.make_app_page({
// 		parent: wrapper,
// 		name: 'Workspaces',
// 		title: __("Workspace"),
//         single_column: true
//     });

// 	frappe.workspace = new frappe.views.Workspace(wrapper);
// 	$(wrapper).bind('show', function () {
// 		frappe.workspace.show();
// 	});
// };


frappe.router.on("change", () => {
	$('.layout-side-section').toggle(false);
	$('[role="menu"]').removeClass('show')
	frappe.__unique_event_id = null;
	frappe.__trigger = {};
});

frappe.views.QueryReport = class QueryReport extends frappe.views.QueryReport {

	setup_events() {
		super.setup_events();
		this.setup_filterable();
	}

	setup_filterable() {
		// filterable events
		this.clear_filters();

		$('.report-wrapper').on("click", ".filterable", (e) => {
			if (e.metaKey || e.ctrlKey) return;
			e.stopPropagation();
			const $this = $(e.currentTarget);
			const key = $this.attr("data-fieldname");
			const value = $this.attr("data-filter");
			this.set_filter_value(key, value);
		});
	}

}

frappe.Application = class Application extends frappe.Application {
	constructor() {
		super()
		if (frappe.boot.print_formats) {
			delete locals['Print Format'];
			frappe.model.sync(frappe.boot.print_formats);
		}
	}

	handle_session_expired() {
		this.redirect_to_login()
	}

	clear_cache() {
		frappe.assets.clear_local_storage();
		frappe.xcall("frappe.sessions.clear").then((message) => {
			location.href = '/app';
		});
	}

	go_to_company() {
		frappe.set_route("Form", "Company", frappe.boot.company);
	}

	switch_user() {
		const me = this;
		var d = new frappe.ui.Dialog({
			title: __("Select User"),
			fields: [
				{
					'fieldname': 'user',
					'fieldtype': 'Link',
					'label': __('User'),
					"options": 'User',
					'reqd': 1
				}
			],
		});
		d.set_primary_action(__('Switch'), function() {
			d.hide();
			var args = d.get_values();
			frappe.call({
				args,
				method: "finse.whitelist.switch_user",
				callback: function (r) {
					me.clear_cache()
				}
			});
		});

		d.show();
	}

	switch_company() {
		const me = this;
		var d = new frappe.ui.Dialog({
			title: __("Select Company"),
			fields: [
				{
					'fieldname': 'company',
					'fieldtype': 'Autocomplete',
					'label': __('Company'),
					"get_query": function () {
						return {
							query: 'finse.whitelist.get_company_list',
							filters: {
								"company_id": ["!=", frappe.boot.company]
							}
						};
					},
					'reqd': 1
				}
			],
		});
		d.set_primary_action(__('Switch'), function() {
			d.hide();
			var args = d.get_values();
			frappe.call({
				args,
				method: "finse.whitelist.switch_company",
				callback: function (r) {
					me.clear_cache();
				}
			});
		});

		if (frappe.user.has_role('Admin')) {
			d.set_secondary_action_label(__("New Company"))
			d.set_secondary_action(function() {
				d.hide();
				frappe.set_route('setup')
			});
		}
		d.show();
	}
}

frappe.ui.form.States =  class FormStates extends frappe.ui.form.States {
	setup_help() {}
}

frappe.breadcrumbs = Object.assign(frappe.breadcrumbs, {
	update() {
		var breadcrumbs = this.all[frappe.breadcrumbs.current_page()];

		this.clear();
		if (!breadcrumbs) return this.toggle(false);

		if (breadcrumbs.type === 'Custom') {
			this.set_custom_breadcrumbs(breadcrumbs);
		} else {
			// Home
			//this.set_home_breadcrumb(breadcrumbs)

			// workspace
			//this.set_workspace_breadcrumb(breadcrumbs);

			// form / print
			let view = frappe.get_route()[0];
			view = view ? view.toLowerCase() : null;
			if (breadcrumbs.doctype && ["print", "form"].includes(view)) {
				this.set_list_breadcrumb(breadcrumbs);
				this.set_form_breadcrumb(breadcrumbs, view);
			} else if (breadcrumbs.doctype && view === 'list') {
				this.set_list_breadcrumb(breadcrumbs);
			} else if (breadcrumbs.doctype && view == 'dashboard-view') {
				this.set_list_breadcrumb(breadcrumbs);
				this.set_dashboard_breadcrumb(breadcrumbs);
			}
		}

		this.toggle(true);
	},

	// set_home_breadcrumb(breadcrumbs) {
	// 	$(`<li><a id="home-breadcrumb" href="/app">Home</a></li>`)
	// 		.appendTo(this.$breadcrumbs);
	// }
})

let get_user_default = frappe.defaults.get_user_default;

frappe.defaults.get_user_default = function(key) {
	if (key.toLowerCase() == 'company') {
		return frappe.boot.company;
	}

	return get_user_default(key)
}

frappe.utils.fetch_link_title = function(doctype, name) {
	return Promise.resolve(name.replace(new RegExp(` *- *(${frappe.boot.company}|${frappe.boot.company_abbr})`), ''))
}

// external
var tree = document.getElementById("treemenu");
if (tree) {
    tree.querySelectorAll("ul").forEach(function (el, key, parent) {
        var elm = el.parentNode;
        elm.classList.add("branch");
        var x = document.createElement("i");
        x.classList.add("indicator");
        x.classList.add("bi-chevron-down");
        elm.insertBefore(x, elm.firstChild);
        el.classList.add("collapse");

        elm.addEventListener(
            "click",
            function (event) {
                if (elm === event.target || elm === event.target.parentNode) {
                    if (el.classList.contains("collapse")) {
                        el.classList.add("expand");
                        el.classList.remove("collapse");
                        x.classList.remove("bi-chevron-down");
                        x.classList.add("bi-chevron-up");
                    } else {
                        el.classList.add("collapse");
                        el.classList.remove("expand");
                        x.classList.remove("bi-chevron-up");
                        x.classList.add("bi-chevron-down");
                    }
                }
            },
            false
        );
    });
}


// customize javascript
$(document).ready(function () {
	let doctypes = [
		  {name:"Gear Icon",id:"gear-icon", has_permission:1, is_report:0},

		  {name:"Customer",id:"customer-list", has_permission:1, is_report:0},
		  {name:"Quotation",id:"quotation", has_permission:1, is_report:0},
		  {name:"Sales Order",id:"sales-order", has_permission:1, is_report:0},
		  {name:"Sales Invoice",id:"sales-invoice", has_permission:1, is_report:0},
		  {name:"Sales Invoice",id:"credit-note", has_permission:1, is_report:0},
		  {name:"Supplier",id:"supplier", has_permission:1, is_report:0},
		  {name:"Purchase Order",id:"purchase-order", has_permission:1, is_report:0},
		  {name:"Purchase Receipt",id:"purchase-receipt", has_permission:1, is_report:0},
		  {name:"Purchase Invoice",id:"purchase-invoice", has_permission:1, is_report:0},
		  {name:"Purchase Invoice",id:"debit-note", has_permission:1, is_report:0},
		  {name:"Expense Claim",id:"expense-claim", has_permission:1, is_report:0},
		  {name:"Employee Advance",id:"employee-advances", has_permission:1, is_report:0},
		  {name:"ToDo",id:"todo", has_permission:1, is_report:0},
		  {name:"Project",id:"project", has_permission:1, is_report:0},
		  {name:"Employee",id:"employee-list", has_permission:1, is_report:0},
		  {name:"User",id:"user-list", has_permission:1, is_report:0},
		  {name:"Payment Entry",id:"payment-entry", has_permission:1, is_report:0},
		  {name:"Journal Entry",id:"journal-entry", has_permission:1, is_report:0},
		  {name:"Stock Entry",id:"stock-entry", has_permission:1, is_report:0},
		  {name:"Stock Reconciliation",id:"stock-reconciliation", has_permission:1, is_report:0},
		  {name:"Payment Reconciliation",id:"payment-reconciliation", has_permission:1, is_report:0},
		  {name:"Auto Repeat",id:"auto-repeat", has_permission:1, is_report:0},

		  {name:"Trial Balance", doctype:"GL Entry", id:"trial-balance", has_permission:1, is_report:1},
		  {name:"Accounts Receivable", doctype:"Sales Invoice", id:"accounts-receivable", has_permission:1, is_report:1},
		  {name:"Accounts Payable", doctype:"Purchase Invoice", id:"accounts-payable", has_permission:1, is_report:1},
		  {name:"Asset", id:"asset-register", has_permission:1, is_report:1, is_report_builder:1, doctype:"Asset"},
		  {name:"General Ledger", doctype:"GL Entry", id:"general-ledger", has_permission:1, is_report:1},
		  {name:"GL Entry", id:"gl-entry", has_permission:1, is_report:1, is_report_builder:1, doctype:"GL Entry"},
		  {name:"Profit and Loss Statement", doctype:"GL Entry", id:"profit-loss", has_permission:1, is_report:1},
		  {name:"Profitability Analysis", doctype:"GL Entry", id:"profitability-analysis", has_permission:1, is_report:1},
		  {name:"Balance Sheet", doctype:"GL Entry", id:"balance-sheet", has_permission:1, is_report:1},
		  {name:"Cash Flow", doctype:"Sales Invoice", id:"cashflow", has_permission:1, is_report:1},
		  {name:"Stock", id:"stock-dashboard", has_permission:1, is_report:0, is_dashboard:1},
		  {name:"Stock Analytics", doctype:"Stock Entry", id:"stock-analytics", has_permission:1, is_report:1},
		  {name:"Stock Ledger", doctype:"Stock Ledger Entry", id:"stock-ledger", has_permission:1, is_report:1},
		  {name:"stock-balance", id:"stock-summary", has_permission:1, is_report:0, is_page:1},
		  {name:"Customer Ledger Summary", doctype:"Sales Invoice", id:"customer-ledgers", has_permission:1, is_report:1},
		  {name:"Sales Register", doctype:"Sales Invoice", id:"sales-register", has_permission:1, is_report:1},
		  {name:"Sales Analytics", doctype:"Sales Order", id:"sales-analytics", has_permission:1, is_report:1},
		  {name:"Sales Invoice Trends", doctype:"Sales Invoice", id:"sales-invoice-trend", has_permission:1, is_report:1},
		  {name:"Supplier Ledger Summary", doctype:"Purchase Invoice", id:"supplier-ledgers", has_permission:1, is_report:1},
		  {name:"Purchase Register", doctype:"Purchase Invoice", id:"purchase-register", has_permission:1, is_report:1},
		  {name:"Purchase Analytics", doctype:"Purchase Order", id:"purchase-analutics", has_permission:1, is_report:1},
		  {name:"Purchase Invoice Trends", doctype:"Purchase Invoice", id:"purchase-invoice-trend", has_permission:1, is_report:1},
		  {name:"Employee Billing Summary", doctype:"Timesheet", id:"billing-summary", has_permission:1, is_report:1},
		  {name:"Employee Advance Summary", doctype:"Employee Advance", id:"advance-summary", has_permission:1, is_report:1},
		  {name:"Expense Claim Report", id:"expense-claim-report", has_permission:1, is_report:1, is_report_builder:1, doctype:"Expense Claim"},
		  {name:"Bank Transaction Report", id:"bank-transaction-report", has_permission:1, is_report:1, is_report_builder:1, doctype:"Bank Transaction"},
		  {name:"Bank Reconciliation Statement", doctype:"Journal Entry", id:"bank-reconciliation", has_permission:1, is_report:1},
		  {name:"GST India", id:"gst-reports", has_permission:1, is_report:0, is_dashboard:1},
		  {name:"Tax Withholding Category", id:"withholding-taxes", has_permission:1, is_report:1, is_report_builder:1, doctype:"Tax Withholding Category"},
		  {name:"my-space", id:"my_space", has_permission:1, is_report:0, is_page:1},
		  {name:"finance-dashboard", id:"finance_dashboard", has_permission:1, is_report:0, is_page:1},
		  {name:"roles-and-permission", id:"roles-and-permission", has_permission:1, is_report:0, is_page:1},
		  {name:"import-employee", id:"import-employee", has_permission:1, is_report:0, is_page:1},
		  {name:"Accounts", id:"accounts_dashboard", has_permission:1, is_report:0, is_dashboard:1},
	]

	for(let doc of doctypes){
		if(doc.id == "debit-note" && !["Purchase User", "Purchase Manager", "Accounts User", "Accounts Manager", "Payments Manager"].some(role => frappe.user_roles.includes(role))){
			$("#"+doc.id).addClass("disable-click");
			$("#"+doc.id).css('color', "#9a9a9a");
			continue
		}

		if(doc.id == "gear-icon"){
			if (!frappe.user_roles.includes("Admin")){
				$("#"+doc.id).addClass("disable-click");
				$("#"+doc.id).css('color', "#9a9a9a");
			}
			continue
		}

		if(doc.is_report == 0 && !('is_dashboard' in doc) && !('is_page' in doc)){
			if(!frappe.model.can_read(doc.name)){
				$("#"+doc.id).addClass("disable-click");
				$("#"+doc.id).css('color', "#9a9a9a");
			}
		}
		else if(doc.is_report == 0 && 'is_page' in doc && doc.is_page){
			if(!frappe.boot.allowed_pages.includes(doc.name)){
				$("#"+doc.id).addClass("disable-click");
				$("#"+doc.id).css('color', "#9a9a9a");
			}
		}
		else if(doc.is_report ==1 ){
			if(!frappe.model.can_get_report(doc.doctype)){
				$("#"+doc.id).addClass("disable-click");
				$("#"+doc.id).css('color', "#9a9a9a");
			}
		}

	}

	if (frappe.boot.redirect_to_url && !window.location.href.includes(frappe.boot.redirect_to_url)) {
		window.location.href = frappe.boot.redirect_to_url;
	}

    $('.navbar-nav.toprightmenu .dropdown,.navbar.gpnav_bottom .navbar-nav > li.dropdown').on('click', function (e) {
        var target = $(e.target);
        var dropdown = target.closest('.dropdown-menu');
        if (target.hasClass('show')) {
            $(dropdown).addClass('show');
        } else {
            $(dropdown).removeClass('show');
        }
    });

    // oneclick
    $('a.masterscrolllink').bind('click', function (event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });

});

frappe.ui.form.ControlMultiSelectPills = class ControlMultiSelectPills extends (
	frappe.ui.form.ControlMultiSelectPills
) {
	make_input() {
		super.make_input();
		this.$input_area.on("click", ".btn-remove", (e) => {
			const $target = $(e.currentTarget);
			const $value = $target.closest(".tb-selected-value");

			const value = decodeURIComponent($value.data().value);
			if(typeof(this.rows) == "string"){
				this.rows = this.rows.split(", ")
			}
			this.rows = this.rows.filter((val) => val !== value);

			this.parse_validate_and_set_in_model("");
		});
	}

	parse(value) {
		if (typeof value == "object" || !this.rows) {
			return value;
		}

		if (value && !this.rows.includes(value)) {
			if(typeof(this.rows) == 'object'){
				this.rows.push(value);
			}
			else if(typeof(this.rows) == 'string'){
				this.rows += ", "+value;
			}
			if (this.rows.length) this.set_pill_html(this.rows)
		}

		return this.rows;
	}

	set_pill_html(values) {
		let html = ''
		if(typeof(values) == 'object'){
			html = values.map((value) => this.get_pill_html(value)).join("");
		}
		else if(typeof(values) == 'string'){
			html = values.split(', ').map((value) => this.get_pill_html(value)).join("");
		}

		this.$multiselect_wrapper.find(".tb-selected-value").remove();
		this.$multiselect_wrapper.prepend(html);
	}
}

frappe.ui.form.Form = class Form extends frappe.ui.form.Form{
	refresh_fields(){
		super.refresh_fields()
		// $('.col-lg-2.layout-side-section').toggle(!cur_frm.is_new())
		$('.col-lg-2.layout-side-section').toggle(false)
	}
}

frappe.ui.form.UserQuickEntryForm = class extends frappe.ui.form.QuickEntryForm {
	insert() {
		let me = this;
		return new Promise((resolve) => {
			me.update_doc();
			frappe.call({
				method: "finse.whitelist.user_insert",
				args: {
					doc: me.dialog.doc,
				},
				callback: function (r) {
					me.dialog.hide();
					// delete the old doc
					frappe.model.clear_doc(me.dialog.doc.doctype, me.dialog.doc.name);
					me.dialog.doc = r.message;
					if (frappe._from_link) {
						frappe.ui.form.update_calling_link(me.dialog.doc);
					} else {
						if (me.after_insert) {
							me.after_insert(me.dialog.doc);
						} else {
							me.open_form_if_not_list();
						}
					}
				},
				error: function () {
					if (!me.skip_redirect_on_error) {
						me.open_doc(true);
					}
				},
				always: function () {
					me.dialog.working = false;
					resolve(me.dialog.doc);
				},
				freeze: true,
			});
		});
	}

}

frappe.ui.form.ItemQuickEntryForm = class extends frappe.ui.form.QuickEntryForm {
	set_defaults() {
		super.set_defaults();
		if (cur_frm.doc.doctype == 'Sales Invoice') {
			this.dialog.set_value('is_sales_item', 1)
			this.dialog.set_value('is_purchase_item', 0)
		}
	}
}

frappe.views.ListView = class ListView extends frappe.views.ListView {
	setup_columns() {
		super.setup_columns();
		if (this.settings.add_columns) {
			for (let col of this.settings.add_columns) {
				this.columns.splice(col.index, 0, col);
			}
		}
	}

	show_restricted_list_indicator_if_applicable() {
	}
}

frappe.edit_doc = function(frm) {
	frm.validate_form_action('Cancel');
	frappe.validated = true;
	//frm.script_manager.trigger("before_cancel").then(function () {
		async function after_cancel(r) {
			if (r.exc) {
				frm.handle_save_fail(btn, on_error);
				return;
			}

			frm.refresh();
			//await frm.script_manager.trigger("after_cancel");

			frappe.call({
				method: 'finse.api.update_status_for_edit',
				args: {
					doctype: frm.doctype,
					docname: frm.docname,
				},
				callback() {
					frm.reload_doc();
				}
			});
		};
		frappe.ui.form.save(frm, "cancel", after_cancel);
	//});
}

frappe.ui.form.Toolbar = class Toolbar extends frappe.ui.form.Toolbar {
	set_primary_action(dirty) {
		if (!dirty) {
			// don't clear actions menu if dirty
			this.page.clear_user_actions();
		}

		var status = this.get_action_status();
		if (status) {
			this.set_page_actions(status);
		} else {
			this.page.clear_actions();
			this.current_status = null;
		}
	}
}

if (erpnext) {
	erpnext.toggle_naming_series = function() {
		if(cur_frm.fields_dict.naming_series) {
			if (frappe.boot.naming_settings) {
				naming = frappe.boot.naming_settings.items.filter(item => item.document_type == cur_frm.doctype).map(item => item.naming);

				if (naming.length > 1) {
					cur_frm.set_df_property(
						"naming_series",
						"options",
						naming
					);
					cur_frm.doc.naming_series = naming[0]
					cur_frm.toggle_display("naming_series", true);
				} else {
					cur_frm.toggle_display("naming_series", false);
				}
			} else {
				cur_frm.toggle_display("naming_series", false);
			}
		}
	}
}

frappe.realtime.on("company_switched", function (data) {
	console.log('received company switched message');
	frappe.set_route('/');
});