
	frappe.templates['navbar'] = `<div id="spinner" style="display: none">
	<div class="rect1">
	</div>
	<div class="rect2">
	</div>
	<div class="rect3">
	</div>
	<div class="rect4">
	</div>
	<div class="rect5">
	</div>
</div>

<header class="navbar navbar-expand sticky-top" role="navigation">

	<div class="container">
		<a class="navbar-brand navbar-home" href="/app">
			<img class="app-logo" style="width: {{ navbar_settings.logo_width || 24 }}px" src="{{ frappe.boot.app_logo_url }}">
		</a>
		<ul class="nav navbar-nav d-none d-sm-flex" id="navbar-breadcrumbs"></ul>
		<div class="collapse navbar-collapse justify-content-end">
			<form class="form-inline fill-width justify-content-end" role="search" onsubmit="return false;">
				{% if (frappe.boot.read_only) { %}
					<span class="indicator-pill gray no-indicator-dot" title="{%= __("Your site is getting upgraded.") %}">
						{%= __("Read Only Mode") %}
					</span>
				{% } %}



				<div class="input-group search-bar text-muted hidden">
					<input
						id="navbar-search"
						type="text"
						class="form-control"
						placeholder="{%= __("Search or type a command (Ctrl + G)") %}"
						aria-haspopup="true"
					>
					<span class="search-icon">
						<svg class="icon icon-sm"><use href="#icon-search"></use></svg>
					</span>

				</div>
			</form>
			{% if (frappe.boot["home_page"] != "setup") { %}

			<ul class="navbar-nav">

				<li class="nav-item dropdown dropdown-notifications dropdown-mobile hidden">
					<a
						class="nav-link notifications-icon text-muted"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="true"
						href="#"
						onclick="return false;">
						<span class="notifications-seen">
							<svg class="icon icon-md"><use href="#icon-notification"></use></svg>
						</span>
						<span class="notifications-unseen">
							<svg class="icon icon-md"><use href="#icon-notification-with-indicator"></use></svg>
						</span>
					</a>
					<div class="dropdown-menu notifications-list dropdown-menu-right" role="menu">
						<div class="notification-list-header">
							<div class="header-items"></div>
							<div class="header-actions"></div>
						</div>
						<div class="notification-list-body">
							<div class="panel-notifications"></div>
							<div class="panel-events"></div>
						</div>
					</div>
				</li>
				<li class="nav-item dropdown dropdown-message dropdown-mobile hidden">
					<a
						class="nav-link notifications-icon text-muted"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="true"
						href="#"
						onclick="return false;">
						<span>
							<svg class="icon icon-md"><use href="#icon-small-message"></use></svg>
						</span>
					</a>
				</li>
				<li class="vertical-bar d-none d-sm-block"></li>
				<li class="nav-item dropdown dropdown-new dropdown-mobile d-none d-lg-block">
					<button class="btn btn-warning btn-sm" data-toggle="dropdown" data-offset="10,20">
						<svg class="icon  icon-xs" style="">
							<use class="" href="#icon-add"></use>
						</svg>
						{{ __("New") }}
					</button>
					<div class="dropdown-menu dropdown-menu-center" id="toolbar-new" role="menu">
						<span class="dropdown-menu-arrow"></span>
						<div class="row finse-new-menu">

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="General Ledger"><div class="widget links-widget-box" data-widget-name="15d2d15fe8">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="General Ledger">General Ledger</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/journal-entry/new" class="link-item ellipsis  " type="Link" title="Journal Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Journal Entry</span>
							</a><a href="/app/journal-entry-template/new" class="link-item ellipsis  " type="Link" title="Journal Entry Template">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Journal Entry Template</span>
							</a><a href="/app/payment-entry/new?mode_of_payment=Wire Transfer" class="link-item ellipsis  " type="Link" title="Payment Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Payment Entry</span>
							</a><a href="/app/payment-entry/new?payment_type=Internal Transfer&mode_of_payment=Wire Transfer" class="link-item ellipsis  " type="Link" title="Transfer">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Transfer</span>
							</a><a href="/app/bank-reconciliation-tool/Bank%20Reconciliation%20Tool" class="link-item ellipsis  " type="Link" title="Bank Reconciliation Tool">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Bank Reconciliation Tool</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Receivable"><div class="widget links-widget-box" data-widget-name="77fe7d9c79">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Customer">Customer</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/customer/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Customer">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Customer</span>
						</a><a href="/app/quotation/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Quotation">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Quotation/Proforma Invoice</span>
						</a><a href="/app/sales-order/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Sales Order">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Sales Order</span>
						</a><a href="/app/sales-invoice/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Invoice">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Invoice</span>
							</a><a href="/app/sales-invoice/new?is_return=1" class="link-item ellipsis onboard-spotlight " type="Link" title="Invoice">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Credit Note</span>
						</a><a href="/app/sales-invoice/new?is_pos=1" class="link-item ellipsis onboard-spotlight " type="Link" title="Invoice">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Cash Memo</span>
					</a><a href="/app/payment-entry/new?payment_type=Receive&party_type=Customer&mode_of_payment=Wire Transfer" class="link-item ellipsis  " type="Link" title="Payment Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Receive Payment</span>
							</a><a href="/app/payment-reconciliation?party_type=Customer" class="link-item ellipsis  " type="Link" title="Payment Reconciliation">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Payment Reconciliation</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Payable"><div class="widget links-widget-box" data-widget-name="f7b728991e">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Accounts Payable">Supplier</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/supplier/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Supplier">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Supplier</span>
						</a><a href="/app/purchase-order/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Purchase Order">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Purchase Order</span>
						</a><a href="/app/purchase-invoice/new?is_expense=1" class="link-item ellipsis onboard-spotlight " type="Link" title="Purchase">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Non-Itemised Purchase</span>
							</a><a href="/app/purchase-invoice/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Bill">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Itemised Purchase</span>
						</a><a href="/app/purchase-invoice/new?is_return=1" class="link-item ellipsis onboard-spotlight " type="Link" title="Debit Note">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Debit Note</span>
						</a><a href="/app/payment-entry/new?payment_type=Pay&party_type=Supplier&mode_of_payment=Wire Transfer" class="link-item ellipsis  " type="Link" title="Payment Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Bill Payment</span>
							</a><a href="/app/payment-reconciliation?party_type=Supplier" class="link-item ellipsis  " type="Link" title="Bill Payment Reconciliation">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Bill Payment Reconciliation</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>
						
						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Payable"><div class="widget links-widget-box" data-widget-name="f7b728991e">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Others">Employee</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/employee/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Employee">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Employee</span>
							</a><a href="/app/expense-claim/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Expense Claim">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Expense Claim</span>
							</a><a href="/app/employee-advance/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Employee Advance">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Employee Advance</span>
							</a><a href="/app/project/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Timesheet">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Project</span>
							</a><a href="/app/timesheet/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Timesheet">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Timesheet</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Payable"><div class="widget links-widget-box" data-widget-name="f7b728991e">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Others">Others</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/user/new" class="link-item ellipsis onboard-spotlight " type="Link" title="User">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">User</span>
							</a><a href="/app/item/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Product/Service">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Product/Service</span>
						</a><a href="/app/activity-cost/new" class="link-item ellipsis onboard-spotlight " type="Link" title="Activity Cost">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Activity Cost</span>
						</a><a href="{{ frappe.boot.quickbooks_migrator_url}}" class="link-item ellipsis onboard-spotlight " type="Link" title="QuickBooks Migrator">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">QuickBooks Migrator</span>
						</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>
					</div>
						<!-- {% for item in navbar_settings.create_dropdown %}
							{% if (!item.hidden) { %}
								{% if (item.route) { %}
									<a class="dropdown-item" href="{{ item.route }}">
										{%= __(item.item_label) %}
									</a>
								{% } else if (item.action) { %}
									<a class="dropdown-item" onclick="return {{ item.action }}">
										{%= __(item.item_label) %}
									</a>
								{% } else { %}
									<div class="dropdown-divider"></div>
								{% } %}
							{% } %}
						{% endfor %} -->
					</div>
				</li>
				<li class="nav-item dropdown dropdown-new dropdown-mobile d-none d-lg-block">
					<button class="btn btn-success btn-sm" data-toggle="dropdown" data-offset="10,20">
						<svg class="icon  icon-xs" style="">
							<use class="" href="#icon-list"></use>
						</svg>
						{{ __("List") }}
					</button>
					<div class="dropdown-menu dropdown-menu-center" id="toolbar-new" role="menu">
						<span class="dropdown-menu-arrow"></span>

						<div class="row finse-new-menu">

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="General Ledger"><div class="widget links-widget-box" data-widget-name="15d2d15fe8">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="General Ledger">General Ledger</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/account/view/tree" class="link-item ellipsis onboard-spotlight " type="Link" title="Chart of Accounts">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Chart of Accounts</span>
							</a><a href="/app/journal-entry" class="link-item ellipsis  " type="Link" title="Journal Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Journal Entry</span>
							</a><a href="/app/journal-entry-template" class="link-item ellipsis  " type="Link" title="Journal Entry Template">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Journal Entry Template</span>
							</a><a href="/app/payment-entry" class="link-item ellipsis  " type="Link" title="Payment Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Payment Entry</span>
							</a><a href="/app/payment-entry?payment_type=Internal Transfer" class="link-item ellipsis  " type="Link" title="Transfer">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Transfer</span>
							</a><a href="/app/bank-reconciliation-tool/Bank%20Reconciliation%20Tool" class="link-item ellipsis  " type="Link" title="Bank Reconciliation Tool">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Bank Reconciliation Tool</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Receivable"><div class="widget links-widget-box" data-widget-name="77fe7d9c79">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Accounts Receivable">Customer
						</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/customer?disabled=0" class="link-item ellipsis onboard-spotlight " type="Link" title="Customer">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Customer</span>
						</a><a href="/app/quotation" class="link-item ellipsis onboard-spotlight " type="Link" title="Quotation">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Quotation/Proforma Invoice</span>
						</a><a href="/app/sales-order" class="link-item ellipsis onboard-spotlight " type="Link" title="Sales Order">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Sales Order</span>
						</a><a href="/app/sales-invoice?is_return=0&is_pos=0" class="link-item ellipsis onboard-spotlight " type="Link" title="Invoice">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Invoice</span>
							</a><a href="/app/sales-invoice/?is_return=1" class="link-item ellipsis onboard-spotlight " type="Link" title="Credit Note">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Credit Note</span>
						</a><a href="/app/sales-invoice/?is_pos=1" class="link-item ellipsis onboard-spotlight " type="Link" title="Cash Memo">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Cash Memo</span>
					</a><a href="/app/payment-entry/?payment_type=Receive&party_type=Customer" class="link-item ellipsis  " type="Link" title="Payment Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Received Payments</span>
							</a><a href="/app/payment-reconciliation?party_type=Customer" class="link-item ellipsis  " type="Link" title="Payment Reconciliation">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Payment Reconciliation</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Payable"><div class="widget links-widget-box" data-widget-name="f7b728991e">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Accounts Payable">Supplier</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/supplier?disabled=0" class="link-item ellipsis onboard-spotlight " type="Link" title="Supplier">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Supplier</span>
						</a><a href="/app/purchase-order" class="link-item ellipsis onboard-spotlight " type="Link" title="Purchase Order">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Purchase Order</span>
						</a><a href="/app/purchase-invoice/?is_expense=1&is_return=0" class="link-item ellipsis onboard-spotlight " type="Link" title="Purchase">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Non-Itemised Purchase</span>
							</a><a href="/app/purchase-invoice/?is_expense=0&is_return=0" class="link-item ellipsis onboard-spotlight " type="Link" title="Bill">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Itemised Purchase</span>
						</a><a href="/app/purchase-invoice/?is_return=1" class="link-item ellipsis onboard-spotlight " type="Link" title="Debit Note">
							<span class="indicator-pill no-margin gray"></span>
							<span class="link-content ellipsis">Debit Note</span>
						</a><a href="/app/payment-entry/?payment_type=Pay&party_type=Supplier" class="link-item ellipsis  " type="Link" title="Payment Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Bill Payment</span>
							</a><a href="/app/payment-reconciliation?party_type=Supplier" class="link-item ellipsis  " type="Link" title="Bill Payment Reconciliation">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Bill Payment Reconciliation</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Payable"><div class="widget links-widget-box" data-widget-name="f7b728991e">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Others">Employee</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/employee?status=Active" class="link-item ellipsis onboard-spotlight " type="Link" title="Employee">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Employee</span>
							</a><a href="/app/expense-claim" class="link-item ellipsis onboard-spotlight " type="Link" title="Expense Claim">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Expense Claim</span>
							</a><a href="/app/employee-advance" class="link-item ellipsis onboard-spotlight " type="Link" title="Employee Advance">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Employee Advance</span>
							</a><a href="/app/project" class="link-item ellipsis onboard-spotlight " type="Link" title="Timesheet">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Project</span>
							</a><a href="/app/timesheet" class="link-item ellipsis onboard-spotlight " type="Link" title="Timesheet">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Timesheet</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>
						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Payable"><div class="widget links-widget-box" data-widget-name="f7b728991e">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Others">Others</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/user?disabled=0" class="link-item ellipsis onboard-spotlight " type="Link" title="User">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">User</span>
							</a><a href="/app/item?disabled=0" class="link-item ellipsis onboard-spotlight " type="Link" title="Product/Service">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Product/Service</span>
							</a><a href="/app/activity-cost?disabled=0" class="link-item ellipsis onboard-spotlight " type="Link" title="Activity Cost">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Activity Cost</span>
							</a><a href="/app/version" class="link-item ellipsis onboard-spotlight " type="Link" title="Audit Log">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Audit Log</span>
							</a><a href="/app/file" class="link-item ellipsis onboard-spotlight " type="Link" title="Attachments">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Attachments</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>
					</div>
						<!-- {% for item in navbar_settings.create_dropdown %}
							{% if (!item.hidden) { %}
								{% if (item.route) { %}
									<a class="dropdown-item" href="{{ item.route }}">
										{%= __(item.item_label) %}
									</a>
								{% } else if (item.action) { %}
									<a class="dropdown-item" onclick="return {{ item.action }}">
										{%= __(item.item_label) %}
									</a>
								{% } else { %}
									<div class="dropdown-divider"></div>
								{% } %}
							{% } %}
						{% endfor %} -->
					</div>
				</li>
				<li class="nav-item dropdown dropdown-new dropdown-mobile d-none d-lg-block">
					<button class="btn btn-info btn-sm" data-toggle="dropdown">
						<svg class="icon  icon-xs" style="">
							<use class="" href="#icon-chart"></use>
						</svg>
						{{ __("Report") }}
					</button>
					<div class="dropdown-menu dropdown-menu-center" id="toolbar-new" role="menu">
						<span class="dropdown-menu-arrow"></span>

						<div class="row finse-new-menu">

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="General Ledger"><div class="widget links-widget-box" data-widget-name="15d2d15fe8">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="General Ledger">General Ledger</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/query-report/General Ledger" class="link-item ellipsis  " type="Link" title="Journal Entry">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">General Ledger</span>
						</a><a href="/app/query-report/Balance Sheet" class="link-item ellipsis  " type="Link" title="Journal Entry">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Balance Sheet</span>
							</a><a href="/app/query-report/Profit and Loss Statement" class="link-item ellipsis  " type="Link" title="Journal Entry Template">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Profit and Loss</span>
							</a><a href="/app/query-report/Cash Flow" class="link-item ellipsis  " type="Link" title="General Ledger">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Cash Flow</span>
							</a><a href="/app/query-report/Trial Balance" class="link-item ellipsis  " type="Link" title="General Ledger">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Trial Balance</span>
							</a><a href="/app/query-report/Trial Balance (Simple)" class="link-item ellipsis  " type="Link" title="General Ledger">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Trial Balance (Simple)</span>
							</a><a href="/app/query-report/Trial Balance for Party" class="link-item ellipsis  " type="Link" title="General Ledger">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Trial Balance for Party</span>
							</a><a href="/app/query-report/Gross and Net Profit Report" class="link-item ellipsis  " type="Link" title="General Ledger">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Gross and Net Profit</span>
							</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Receivable"><div class="widget links-widget-box" data-widget-name="77fe7d9c79">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Accounts Receivable">Customer
						</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/query-report/Customer Ledger Summary" class="link-item ellipsis  " type="Link" title="Customer Ledger">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Customer Ledger</span>
							</a><a href="/app/query-report/Accounts Receivable" class="link-item ellipsis  " type="Link" title="Accounts Receivable">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Accounts Receivable</span>
							</a><a href="/app/query-report/Accounts Receivable Simple" class="link-item ellipsis  " type="Link" title="Accounts Receivable Simple">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Accounts Receivable Simple</span>
						</a><a href="/app/query-report/Accounts Receivable Summary" class="link-item ellipsis  " type="Link" title="Accounts Receivable Summary">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Accounts Receivable Ageing</span>
							</a><a href="/app/query-report/Sales Payment Summary" class="link-item ellipsis  " type="Link" title="Sales Payment Summary">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Sales & Payments</span>
							</a><a href="/app/query-report/Sales Register" class="link-item ellipsis  " type="Link" title="Sales Register">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Sales Register</span>
							</a><a href="/app/query-report/Item-wise Sales Register" class="link-item ellipsis  " type="Link" title="Item-wise Sales Register">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Item-wise Sales Register</span>
							</a><a href="/app/query-report/Sales Invoice Trends" class="link-item ellipsis  " type="Link" title="Item-wise Sales Register">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Invoice Trends</span>
						</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>

						<div class="ce-block col-xs-12 col-sm-6 col-md-4"><div class="ce-block__content"><div card_name="Accounts Payable"><div class="widget links-widget-box" data-widget-name="f7b728991e">
							<div class="widget-head">
								<div class="widget-label">
									<div class="widget-title"><svg class="icon  icon-lg" style="">
							<use class="" href="#icon-file"></use>
						</svg> <span class="ellipsis" title="Accounts Payable">Supplier</span></div>
									<div class="widget-subtitle"></div>
								</div>
								<div class="widget-control"></div>
							</div>
							<div class="widget-body"><a href="/app/query-report/Supplier Ledger Summary" class="link-item ellipsis  " type="Link" title="Accounts Receivable">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Supplier Ledger</span>
							</a><a href="/app/query-report/Accounts Payable" class="link-item ellipsis  " type="Link" title="Accounts Payable">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Accounts Payable</span>
							</a><a href="/app/query-report/Accounts Payable Simple" class="link-item ellipsis  " type="Link" title="Accounts Payable Simple">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Accounts Payable Simple</span>
						</a><a href="/app/query-report/Accounts Payable Summary" class="link-item ellipsis  " type="Link" title="Accounts Payable Summary">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Accounts Payable Ageing</span>
							</a><a href="/app/query-report/TDS Computation Summary" class="link-item ellipsis  " type="Link" title="TDS Computation Summary">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">TDS Computation Summary</span>
							</a><a href="/app/query-report/TDS Payable Monthly" class="link-item ellipsis  " type="Link" title="TDS Payable Monthly">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">TDS Payable Monthly</span>
							</a><a href="/app/query-report/Purchase Register" class="link-item ellipsis  " type="Link" title="Purchase Register">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Purchase Register</span>
							</a><a href="/app/query-report/Item-wise Purchase Register" class="link-item ellipsis  " type="Link" title="Item-wise Purchase Register">
									<span class="indicator-pill no-margin gray"></span>
									<span class="link-content ellipsis">Item-wise Purchase Register</span>
							</a><a href="/app/query-report/Purchase Invoice Trends" class="link-item ellipsis  " type="Link" title="Item-wise Sales Register">
								<span class="indicator-pill no-margin gray"></span>
								<span class="link-content ellipsis">Bill Trends</span>
						</a></div>
							<div class="widget-footer"></div>
						</div></div></div></div>
					</div>
						<!-- {% for item in navbar_settings.create_dropdown %}
							{% if (!item.hidden) { %}
								{% if (item.route) { %}
									<a class="dropdown-item" href="{{ item.route }}">
										{%= __(item.item_label) %}
									</a>
								{% } else if (item.action) { %}
									<a class="dropdown-item" onclick="return {{ item.action }}">
										{%= __(item.item_label) %}
									</a>
								{% } else { %}
									<div class="dropdown-divider"></div>
								{% } %}
							{% } %}
						{% endfor %} -->
					</div>
				</li>
				<li class="nav-item dropdown dropdown-help dropdown-mobile d-none d-lg-block">
					<a class="nav-link" data-toggle="dropdown" href="#" onclick="return false;">
						{{ __("Help") }}
						<span>
							<svg class="icon icon-xs"><use href="#icon-small-down"></use></svg>
						</span>
					</a>
					<div class="dropdown-menu dropdown-menu-right" id="toolbar-help" role="menu">
						<div id="help-links"></div>
						<div class="dropdown-divider documentation-links"></div>
						{% for item in navbar_settings.help_dropdown %}
							{% if (!item.hidden) { %}
								{% if (item.route) { %}
									<a class="dropdown-item" href="{{ item.route }}">
										{%= __(item.item_label) %}
									</a>
								{% } else if (item.action) { %}
									<a class="dropdown-item" onclick="return {{ item.action }}">
										{%= __(item.item_label) %}
									</a>
								{% } else { %}
									<div class="dropdown-divider"></div>
								{% } %}
							{% } %}
						{% endfor %}
					</div>
				</li>
				{% if (frappe.session.user == 'Administrator') { %}
				<li class="nav-item">
					<span class="company-name" onclick="frappe.app.switch_user()">
						<span><svg class="icon icon-lg"><use href="#icon-change"></use></svg></span>
					</span>
				</li>
				{% } %}
				<li class="nav-item">
					<span class="company-name" onclick="frappe.app.switch_company()">
						{%= frappe.boot.company  %}
						<span>        <svg class="icon icon-xs"><use href="#icon-small-down"></use></svg>       </span>
					</span>
				</li>
				<li class="nav-item">
						<a
							class="nav-link settings-icon text-muted"
							href="/app/finse-settings">
							<span class="setting">
								<svg class="icon icon-md"><use href="#icon-setting-gear"></use></svg>
							</span>
						</a>
				</li>
				<li class="nav-item dropdown dropdown-navbar-user dropdown-mobile">
					<a class="nav-link" data-toggle="dropdown" href="#" onclick="return false;">
						{{ avatar }}
					</a>
					<div class="dropdown-menu dropdown-menu-right" id="toolbar-user" role="menu">
						{% for item in navbar_settings.settings_dropdown %}
							{% if (!item.hidden && (item.item_label != 'New Company' || frappe.user.has_role('Admin'))) { %}
								{% if (item.route) { %}
									<a class="dropdown-item" href="{{ item.route }}">
										{%= __(item.item_label) %}
									</a>
								{% } else if (item.action) { %}
									<a class="dropdown-item" onclick="return {{ item.action }}">
										{%= __(item.item_label) %}
									</a>
								{% } else { %}
									<div class="dropdown-divider"></div>
								{% } %}
							{% } %}
						{% endfor %}
					</div>
				</li>
			</ul>
			{% } %}

		</div>
	</div>
</header>
`;
